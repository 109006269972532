<template>
  <div>
    <show-top-agent-self-subscribe-order v-if="this.$store.getters.userRole === 'top_agent'" />
  </div>
</template>

<script>

export default {
  name: 'ShowSubscribeTrafficPoolOrder',
  components: {
    ShowTopAgentSelfSubscribeOrder: () => import('@/views/agent_monthly_bills/subscribe_traffic_pool/top_agent_self/Show')
  }
}
</script>
